/** @jsx jsx */
import { jsx, Box, Text, Button } from "theme-ui"
import { theme } from "gatsby-plugin-theme-ui/index"
import React, { ReactNode, useState, useEffect } from "react"

interface PaginationProps {
  children: ReactNode[]
  resultsPerPage: number
  className?: string
}
const Pagination = ({
  children,
  resultsPerPage,
  className
}: PaginationProps) => {
  const [pages, setPages] = useState<ReactNode[]>([])
  const createPages = () => {
    const newPages = []

    for (let i = 0; i < Math.ceil(children.length / resultsPerPage); i++) {
      const newPage = []

      for (
        let j = i === 0 ? i : i + resultsPerPage - 1;
        j < i + resultsPerPage;
        j++
      ) {
        if (children[j]) {
          newPage.push(children[j])
        } else {
          break
        }
      }

      if (newPage.length > 0) newPages.push(newPage)
    }

    if (newPages.length > 0) setPages(newPages)
  }
  useEffect(createPages, [])

  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <Box>
      {pages.length > 0 &&
        pages.map((page, index) => (
          <Box
            key={`pagination-page:${index}`}
            sx={{
              display: currentIndex === index ? "block" : "none"
            }}
          >
            {page}
          </Box>
        ))}
      <ul
        sx={{
          p: 0,
          mt: [5, 6],
          display: "flex",
          justifyContent: "center",
          listStyle: "none",
          li: {
            mx: 3
          }
        }}
      >
        <li>
          <Button
            variant="buttons.pagination"
            sx={{
              cursor: pages.length > 1 ? "pointer" : "default"
            }}
            onClick={() =>
              setCurrentIndex(
                currentIndex === 0 ? pages.length - 1 : currentIndex - 1
              )
            }
          >
            {"<"}
          </Button>
        </li>
        {pages.length > 0 ? (
          pages.map((child, index) => (
            <li key={`PaginationButtons:${index}`}>
              <Button
                variant={
                  currentIndex === index ? "paginationActive" : "pagination"
                }
                onClick={() => setCurrentIndex(index)}
              >
                {index + 1}
              </Button>
            </li>
          ))
        ) : (
          <li>
            <Button variant="paginationActive">1</Button>
          </li>
        )}
        <li>
          <Button
            variant="buttons.pagination"
            sx={{
              cursor: pages.length > 1 ? "pointer" : "default"
            }}
            onClick={() =>
              setCurrentIndex(
                currentIndex === pages.length - 1 ? 0 : currentIndex + 1
              )
            }
          >
            {">"}
          </Button>
        </li>
      </ul>
    </Box>
  )
}

export default Pagination

/** @jsx jsx */
import { jsx, Card, Box, Heading, Paragraph } from "theme-ui"
import { theme } from "gatsby-plugin-theme-ui/index"
import { Link } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"

interface CaseStudyCardProps {
  hero: any
  slug: string
  title: string
  preview: string
  cardSide: "RIGHT" | "LEFT"
  className?: string
}
const CaseStudyCard = ({
  hero,
  slug,
  title,
  preview,
  cardSide,
  className
}: CaseStudyCardProps) => {
  const columnDirection = cardSide === "RIGHT" ? "column" : "column-reverse"

  return (
    <Card
      variant="caseStudy"
      className={className}
      sx={{
        mb: [5, 0],
        flexDirection: [columnDirection, "row"]
      }}
    >
      {cardSide === "RIGHT" && (
        <GatsbyImage
          image={
            getImage(
              hero.localFile.childImageSharp.gatsbyImageData
            ) as IGatsbyImageData
          }
          alt={hero.alternativeText}
          className="thumbnail"
        />
      )}
      <Box
        className="content"
        sx={{
          ml: [0, cardSide === "RIGHT" ? -5 : 0],
          mr: [0, cardSide === "LEFT" ? -5 : 0],
          mt: [-5, 0]
        }}
      >
        <Box>
          <Heading as="h3" variant="styles.h3">
            {title}
          </Heading>
          <Paragraph
            sx={{
              mt: [2, 4]
            }}
          >
            {preview}
          </Paragraph>
        </Box>
        <Link to={`/case-studies/${slug}`} sx={theme?.links?.arrow}>
          Read now
        </Link>
      </Box>
      {cardSide === "LEFT" && (
        <GatsbyImage
          image={
            getImage(
              hero.localFile.childImageSharp.gatsbyImageData
            ) as IGatsbyImageData
          }
          alt={hero.alternativeText}
          className="thumbnail"
        />
      )}
    </Card>
  )
}

export default CaseStudyCard

/** @jsx jsx */
import { jsx, Container } from "theme-ui"
import { graphql } from "gatsby"

import Layout from "components/Common/Layout"
import PurpleHero from "components/PurpleHero"
import CaseStudyCard from "components/case-studies/CaseStudyCard"
import Pagination from "components/Common/Pagination"
import MoveYourBrandCard from "components/Footer/MoveYourBrandCard"

const CaseStudiesPage = ({
  data: {
    strapiCaseStudiesParentPage: { SEO },
    allStrapiCaseStudy
  }
}) => {
  return (
    <Layout
      seo={{
        title: SEO.meta_title,
        description: SEO.meta_description,
        shareImage: SEO.share_image
      }}
    >
      <main>
        <PurpleHero heading="Case Studies" />

        <Container variant="container1000">
          <Pagination resultsPerPage={4}>
            {allStrapiCaseStudy.nodes.map((caseStudy, index) => (
              <CaseStudyCard
                key={`case-study-card:${index}`}
                {...caseStudy}
                cardSide={index % 2 === 0 ? "RIGHT" : "LEFT"}
                sx={{
                  mt: [5, 6]
                }}
              />
            ))}
          </Pagination>
        </Container>
      </main>

      <MoveYourBrandCard
        sx={{
          m: "0 auto",
          position: "relative",
          zIndex: 2,
          bottom: [-6, -5]
        }}
      />
    </Layout>
  )
}

export default CaseStudiesPage

export const caseStudiesPageQuery = graphql`
  query CaseStudiesPage {
    strapiCaseStudiesParentPage {
      SEO {
        meta_title
        meta_description
        share_image {
          localFile {
            publicURL
          }
        }
      }
    }
    allStrapiCaseStudy {
      nodes {
        title
        preview
        hero {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        slug
        blocks
      }
    }
  }
`
